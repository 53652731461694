import InlineSvg from 'vue-inline-svg';
import {formatObject, formatRegelingen} from "@/main";
import Gemeentelogo from '@/components/Gemeentelogo.vue';
import {mapActions} from "vuex";

export default {
    components: {
        Gemeentelogo,
        InlineSvg,
    },
    data() {
        return this.$store.getters.getState;
    },
    methods: {
        ...mapActions({
            signOut: 'signOut',
            signIn: 'signIn'
        }),
        bedrag(number, treatment = []) {
            if (typeof (number) === 'string') {
                number = number.replace(',', '.');
                if (treatment.length) {
                    if (treatment.includes('-')) {
                        number = 0 - number;
                    }
                    if (treatment.includes(12)) {
                        number *= 12;
                    }
                }
            }
            return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(number);
        },
        bereken() {
            // console.table({
            //     'ingevuldAanvragerVelden': this.ingevuldAanvragerVelden,
            //     'ingevuldKindVeld': this.ingevuldKindVeld,
            //     'ingevuldPartnerVelden': this.ingevuldPartnerVelden,
            //     'ingevuldWoningVelden': this.ingevuldWoningVelden,
            //     'ingevuldKinderopvangVelden': this.ingevuldKinderopvangVelden,
            //     'ingevuldUrenWerk': this.ingevuldUrenWerk,
            //     'ingevuldVergoedingen': this.ingevuldVergoedingen,
            //     'ingevuldAanvragerLoon': this.ingevuldAanvragerLoon,
            //     'ingevuldPartnerLoon': this.ingevuldPartnerLoon,
            //     'ingevuldAanvragerLeeftijd': this.ingevuldAanvragerLeeftijd,
            //     'ingevuldPartnerLeeftijd': this.ingevuldPartnerLeeftijd
            // });
            this.session.saved = true;
            this.session.error422 = null;
            this.session.errorMessage = null;
            if (this.alleVeldenIngevuld) {
                const aanvrager = formatObject(this.aanvrager, 'data');
                const huurwoning = formatObject(this.huurwoning, 'data');
                const partner = formatObject(this.partner, 'data');
                const kinderopvang = this.kinderopvangToDot(this.kindopvang);
                const regelingen = formatRegelingen(this.regelingen, 'data');
                let data = {
                    aanvrager,
                    gemcode: this.gemeente.gemcode,
                    huurwoning,
                    id: this.id,
                    kenmerk: this.kenmerk,
                    kinderopvang,
                    koopwoning: this.koopwoning,
                    partner,
                    regelingen,
                    kind0: this.kind0,
                    kind4: this.kind4,
                    kind12: this.kind12,
                    kind16: this.kind16,
                }

                this.$axios
                    .post("/api/landelijketoeslagen", data)
                    .then(response => {
                        this.aanvrager = formatObject(response.data.aanvrager, 'euro');
                        this.armoedeval = response.data.armoedeval;
                        this.bedragenarmoedeval = response.data.bedragenarmoedeval;
                        this.combinatiekorting = response.data.combinatiekorting;
                        this.huurtoeslag = response.data.huurtoeslag;
                        this.huurwoning = formatObject(response.data.huurwoning, 'euro');
                        this.kinderopvang = response.data.kinderopvang;
                        this.kindgebondenbudget = response.data.kindgebondenbudget;
                        this.koopwoning = response.data.koopwoning;
                        this.partner = formatObject(response.data.partner, 'euro');
                        this.recht = response.data.recht;
                        this.regelingen = formatRegelingen(response.data.regelingen, 'euro');
                        this.toetsingsinkomen = response.data.toetsingsinkomen;
                        this.toeslagenpermaand = response.data.toeslagenpermaand;
                        this.zorgtoeslag = response.data.zorgtoeslag;
                        this.scrollToBottom();
                    }).then(() => {
                    this.session.send = true;
                })
                    .catch(error => {
                        console.log(1, error);
                        console.log(2, error.response.data);
                        console.log(3, error.response.data.message);

                        if (typeof error.response.data != "undefined") {
                            let message = null;
                            // eslint-disable-next-line no-prototype-builtins
                            if (error.response.data.hasOwnProperty('error')) {
                                message = error.response.data.error;
                                // eslint-disable-next-line no-prototype-builtins
                            } else if (error.response.data.hasOwnProperty('message')) {
                                message = error.response.data.message;
                            } else {
                                message = error.response.data;
                            }
                            if (message) {
                                if (typeof (message) === 'string') {
                                    this.session.errorMessage = message;
                                } else {
                                    let messages = Object.values(message);
                                    let err = []
                                    messages.forEach(a => a.forEach(m => {
                                        if (typeof (m) === 'string') err.push(m.replace('.', ' '))
                                    }));
                                    this.session.error422 = err;
                                }
                            }
                        } else {
                            alert('er is iets misgegaan');
                        }
                    });
            }
        },
        checkGezin() {
            let arr = ['N', '0', '0'];
            while (this.kindopvang['kind0'].length < this.kind0) {
                this.kindopvang['kind0'].push(arr);
            }
            while (this.kindopvang['kind4'].length < this.kind4) {
                this.kindopvang['kind4'].push(arr);
            }
            while (this.kindopvang['kind12'].length < this.kind12) {
                this.kindopvang['kind12'].push(arr);
            }
            while (this.kindopvang['kind16'].length < this.kind16) {
                this.kindopvang['kind16'].push(arr);
            }
            while (this.kindopvang['kind0'].length > this.kind0) {
                this.kindopvang['kind0'].pop();
            }
            while (this.kindopvang['kind4'].length > this.kind4) {
                this.kindopvang['kind4'].pop();
            }
            while (this.kindopvang['kind12'].length > this.kind12) {
                this.kindopvang['kind12'].pop();
            }
            while (this.kindopvang['kind16'].length > this.kind16) {
                this.kindopvang['kind16'].pop();
            }
        },
        eenVeld(type, i) {
            if (this.regelingen[i][type] !== '') {
                this.regelingen[i][type] = '';
            }
        },
        kindopvangIngevuld(kind) {
            //leeg N00 of niet N en bedragen niet leeg weak comparison want '0' en 0 mogelijk
            return (kind[0] === 'N' && (kind[1] === 0 || kind[1] === '0') && (kind[2] === 0 || kind[2] === '0')) || (kind[0] !== 'N' && kind[1] !== '' && kind[2] !== '');
        },
        opslaan() {
            //todo  deze wordt niet meer gebruikt maar moet ipv de landelijketoeslagen call komen
            let self = this;
            let data = {
                data: JSON.stringify(this.$data),
                gemcode: this.gemeente.gemcode
            }
            //update
            if (this.id > 0) {
                this.$store.commit('SET_LOADING', true);

                this.$axios
                    .put(`/api/werkloont/${this.id}`, data)
                    .then(() => {
                        self.saved = true;
                        setTimeout(() => {
                            self.saved = false;
                        }, 2500);
                        this.$store.commit('SET_LOADING', false);
                    })
                    .catch((e) => {
                        console.log('error put: ' + e);
                        this.$store.commit('SET_LOADING', false);
                    });
            }
        },
        periodeLeesbaar(periode) {
            return periode === 'U' ? 'uur' : (periode === 'W' ? 'week' : (periode === 'V' ? '4 wekelijks ' : 'maand'));
        },
        print() {
            window.print();
        },
        profiel(profiel) {
            if (this.kinderen === 0)
                return !['kinderen', 'studentmetkinderen'].includes(profiel);
            else return true;
        },
        resetHuur() {
            this.huurwoning.huur = '';
        },
        resetKinderen() {
            this.kind0 = 0;
            this.kind4 = 0;
            this.kind12 = 0;
            this.kind16 = 0;
            this.checkGezin();
        },
        resetPartner() {
            this.$store.commit('resetPartner');
            this.partner = this.$store.getters.getState.partner;
        },
        resetPartervelden() {
            if (this.partner.looninvoer === 0 || this.partner.looninvoer === '0') {
                let leeftijd = this.partner.leeftijd;
                this.resetPartner();
                this.partner.leeftijd = leeftijd;
            }
        },
        resetWoz() {
            this.koopwoning.financieringskosten = null;
            this.koopwoning.hypotheekrente = null;
            this.koopwoning.woz = null;
        },
        closeModal(i){
            document.getElementById(`dialog${i}`).close();
        },
        openModal(i){
            document.getElementById(`dialog${i}`).showModal();
        },
        scrollToBottom() {
            const container = this.$el.querySelector("#bottom-alert");
            container.scrollIntoView({behavior: "smooth"});
        },
        syncRegelingenGebruiken(i) {
            if (!this.regelingen[i].gebruiken) {
                delete this.regelingen[i].gebruiken;
                delete this.regelingen[i].bedrag;
                delete this.regelingen[i].jaarbedrag;
            }
        },
        ucfirst(str) {
            return str.replace(/^\w/, c => c.toUpperCase());
        },
        update(e) {
            this.kindopvang[e.name] = e.opvangdata;
        },
    },
    computed: {
        is_ingelogd(){
            return this.$store.getters.authenticated;
        },
        aanvragerloon() {
            return this.aanvrager.loon;
        },
        alleVeldenIngevuld() {
            return this.ingevuldAanvragerVelden &&
                this.ingevuldKindVeld &&
                this.ingevuldPartnerVelden &&
                this.ingevuldWoningVelden &&
                this.ingevuldKinderopvangVelden &&
                this.ingevuldUrenWerk &&
                this.ingevuldVergoedingen
        },
        gezin() {
            return this.partner === '1' ? 'E' : (this.kinderen > 0 ? 'AO' : 'A');
        },
        huurOfKoop() {
            return this.session.woning !== '-1';
        },
        ingevuldKinderopvangVelden() {
            let count = 0
            for (let kind in this.kindopvang) {
                this.kindopvang[kind].forEach(k => count += this.kindopvangIngevuld(k) ? 0 : 1);
            }
            return count === 0;
        },
        ingevuldAanvragerLeeftijd() {
            return ['22', '23', 22, 23].includes(this.aanvrager.leeftijd);
        },
        ingevuldAanvragerVelden() {
            return typeof (this.aanvrager) !== 'undefined' &&
                this.ingevuldAanvragerLeeftijd &&
                ['12', '13', 12, 13].includes(this.aanvrager.maanden) &&
                this.ingevuldAanvragerLoon &&
                this.loonsoorten.includes(this.aanvrager.loonsoort);
        },
        ingevuldKindVeld() {
            return ['0', '1'].includes(this.session.kinderen_aanwezig);
        },
        ingevuldAanvragerLoon() {
            return this.aanvrager.looninvoer?.toString().replace(',', '.') > 0;
        },
        ingevuldPartnerLoon() {
            return this.partner.looninvoer.toString().replace(',', '.') > 0;
        },
        ingevuldPartnerLeeftijd() {
            return (this.session.partner_aanwezig === '1' && ['22', '23', 22, 23].includes(this.partner.leeftijd)) || this.session.partner_aanwezig === '0'
        },
        ingevuldPartnerVelden() {
            if (!['0', '1'].includes(this.session.partner_aanwezig)) {
                return false
            }
            if (this.session.partner_aanwezig === '1') {
                if (['22', '23', 22, 23].includes(this.partner.leeftijd) && (this.partner.looninvoer === '0' || this.partner.looninvoer === 0)) {
                    return true;
                }
                return ['22', '23', 22, 23].includes(this.partner.leeftijd) &&
                    ((this.ingevuldPartnerLoon
                        && this.loonsoorten.includes(this.partner.loonsoort) &&
                        ['12', '13', 12, 13].includes(this.partner.maanden)) || ((this.partner.looninvoer === '0' || this.partner.looninvoer === 0)
                        && this.loonsoorten.includes(this.partner.loonsoort)))

            } else return true;
        },
        ingevuldUrenWerk() {
            return this.aanvrager.periode === 'U' ? this.aanvrager.uren_werk_per_week > '0' : true;
        },
        ingevuldUrenWerkPartner() {
            return this.partner.periode === 'U' ? this.partner.uren_werk_per_week > '0' : true;
        },
        ingevuldVergoedingen() {
            let r = 0;
            this.regelingen.forEach(function (regeling) {
                if (
                    regeling.gebruiken &&
                    (typeof (regeling.bedrag) === 'undefined' || regeling.bedrag === '' || regeling.bedrag == null || !regeling.bedrag.match(/\d{1,5}/)) &&
                    (typeof (regeling.jaarbedrag) === 'undefined' || regeling.jaarbedrag === '' || regeling.jaarbedrag == null || !regeling.jaarbedrag.match(/\d{1,5}/))) {
                    r++;
                }
            });
            return r === 0;
        },
        ingevuldWoningVelden() {
            return ['0', '1', '-1'].includes(this.session.woning) && ((this.session.woning === '1' && this.koopwoning.hypotheekrente > 0 && this.koopwoning.woz > '0') ||
                (this.session.woning === '0' && this.huurwoning.huur > '0') ||
                this.session.woning === '-1');
        },
        inkomenMinstverdienende() {
            return 0;
        },
        invulstatus() {
            return JSON.stringify([this.aanvrager, this.partner, this.session.partner_aanwezig, this.session.woning, this.session.kinderen_aanwezig, this.kind0, this.kind4, this.kind12, this.kind16, this.kindopvang, this.huurwoning, this.koopwoning]);
        },
        kinderen() {
            return parseInt(this.kind0) + parseInt(this.kind4) + parseInt(this.kind12) + parseInt(this.kind16);
        },
        leefvormdata() {
            return {leeftijdprofiel: this.aanvrager.leeftijd, partnerprofiel: this.partner.leeftijd, kinderen: this.kinderen}
        },
        loaded() {
            return this.$store.getters.getLoaded;
        },
        logo() {
            return `https://www.berekenuwrechtplus.nl/logo/${this.gemeente.logo}`;
        },
        logoclasses() {
            return `logo ${ this.gemeente.urlnaam }`;
        },
        tooltip() {
            return [
                'toeslag 100% vergoed huur vanaf basishuur tot kwaliteitskortingsgrens',
                'toeslag 65% vanaf kwaliteitskortingsgrens tot aftoppinggrens',
                'toeslag 40% vanaf aftoppingsgrens']
        },
        tooltipkgbverlaging() {
            return `Het bijstandinkomen valt altijd onder het drempelinkomen van: ` + this.bedrag(this.kindgebondenbudget.drempelinkomen) +
                ` dus verlaging 0. Bij werk als het toetsingsinkomen hoger is dan het drempelinkomen is de verlaging: (toetsingsinkomen - drempelinkomen) x verlagingspercentage = `
                + this.bedrag(this.kindgebondenbudget.grens) + ` x ` + this.kindgebondenbudget.verlagingmeerinkomenpercentage / 100 + `% = ` + this.bedrag(this.kindgebondenbudget.verlagingmeerinkomenpercentage / 100 * this.kindgebondenbudget.grens)
                + ` per jaar met een maximum van het te ontvangen bedrag aan kindgebonden budget`;
        },
        volwassenen() {
            return this.session.partner_aanwezig === '1' ? 2 : 1;
        },
        warning() {
            return this.session.saved && !this.alleVeldenIngevuld;
        },
    },

    watch: {
        is_ingelogd: function () {
            if (this.loggedIn) {
                //console.log('loggedId');
            }
        },
        alleVeldenIngevuld() {
            if (!this.alleVeldenIngevuld) {
                this.session.send = false;
            }
        },
        invulstatus() {
            if (this.loaded) {
                this.session.send = false;
            }
        },
        'aanvrager.looninvoer'(v) {
            this.aanvrager.looninvoer = this.decimaalBedrag(v);
        },
        'aanvrager.eenmalige_uitkering'(v) {
            this.aanvrager.eenmalige_uitkering = this.decimaalBedrag(v);
        },
        'aanvrager.ontvangen_alimentatie'(v) {
            this.aanvrager.ontvangen_alimentatie = this.decimaalBedrag(v);
        },
        'aanvrager.betaalde_alimentatie'(v) {
            this.aanvrager.betaalde_alimentatie = this.decimaalBedrag(v);
        },
        'aanvrager.ontvangen_kinderalimentatie'(v) {
            this.aanvrager.ontvangen_kinderalimentatie = this.decimaalBedrag(v);
        },
        'aanvrager.uren_werk_per_week'(v) {
            this.aanvrager.uren_werk_per_week = this.integerBedrag(v);
        },
        'aanvrager.inkomstenvrijlating'(v) {
            this.aanvrager.inkomstenvrijlating = this.decimaalBedrag(v);
        },
        'aanvrager.kostendelerscorrectie'(v) {
            this.aanvrager.kostendelerscorrectie = this.decimaalBedrag(v);
        },
        'aanvrager.verwervingskosten'(v) {
            this.aanvrager.verwervingskosten = this.decimaalBedrag(v);
        },
        'partner.looninvoer'(v) {
            this.partner.looninvoer = this.decimaalBedrag(v);
        },
        'partner.eenmalige_uitkering'(v) {
            this.partner.eenmalige_uitkering = this.decimaalBedrag(v);
        },
        'partner.ontvangen_alimentatie'(v) {
            this.partner.ontvangen_alimentatie = this.decimaalBedrag(v);
        },
        'partner.betaalde_alimentatie'(v) {
            this.partner.betaalde_alimentatie = this.decimaalBedrag(v);
        },
        'partner.uren_werk_per_week'(v) {
            this.partner.uren_werk_per_week = this.integerBedrag(v);
        },
        'koopwoning.woz'(v) {
            this.koopwoning.woz = this.koopwoning.woz ? this.integerBedrag(v) : null;
        },
        'koopwoning.hypotheekrente'(v) {
            this.koopwoning.hypotheekrente = this.koopwoning.hypotheekrente ? this.integerBedrag(v) : null;
        },
        'koopwoning.financieringskosten'(v) {
            this.koopwoning.financieringskosten = this.koopwoning.financieringskosten ? this.integerBedrag(v) : null;
        },
        'huurwoning.huur'(v) {
            this.huurwoning.huur = this.decimaalBedrag(v);
        },
        regelingen: {
            handler(v) {
                v.forEach(r => {
                    if (r.gebruiken) {
                        r.bedrag = this.decimaalBedrag(r.bedrag);
                        r.jaarbedrag = this.decimaalBedrag(r.jaarbedrag);
                    }
                });
            },
            deep: true
        }
    }
}